import React, {Component} from 'react';

export default class ReadingList extends Component {

    render() {
        let chapters = this.props.booklist.chapters.map((chapter=> {
            return <div key={chapter.number}>{chapter.number} : <a href={chapter.s3_url}>{chapter.title}</a></div>
        }));
        return <div className="reading-list">
            <h2>Chapters</h2>
            {chapters}
        </div>
    }
}
