import React from "react";

export default function WelcomeComponent(){
    return <div>
        <h2>What's this all about?</h2>
        <p>This will cost you £2.50 a month, you’ll get a chapter with at least 20 minutes of reading* a month
            until you cancel your subscription. You’ll obviously need to stay subscribed for 12 months to get
            the full story. Your subscription will automatically renew in 12 months time when you'll get the
            first chapter of the next book (details to follow when it's a bit further on).</p>
        <p>You can cancel at any time and if you do cancel, you’ll get to keep the chapters that you already
            have, although you won’t get any new chapters.</p>
        <p><a href="https://ruthwrites.online/the-lost-things">Read a bit more about "The Lost Things"</a></p>
        <p><a href="https://ruthwrites.online/book-by-subscription">Read a bit more about why I'm trying this
            "book as a service" thing.</a></p>
        <p>* I'm aiming for 20 minutes based on a reading time of 265 words per minute <a
            href="https://help.medium.com/hc/en-us/articles/214991667-Read-time">like Medium</a></p>
    </div>
};
