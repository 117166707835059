import React, {Component} from 'react';
import {rootURL, checkoutURL} from './Settings';
import LoginComponent from './LoginComponent';
import PayComponent from './PayComponent';
import ReadingList from './ReadingList';
import WelcomeComponent from './WelcomeComponent';
import CancelledComponent from './CancelledComponent';

import './App.css';


async function postData(url = '', data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json'
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *client
        body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    return await response.json(); // parses JSON response into native JavaScript objects
}

const checkout_url = `${checkoutURL}/user`;

function orLoader(predicate, Component){
    return function FinalComponent(props) {
        return predicate(props)
            ? <div><img src="/loader.gif" alt=""/></div>
            : <Component { ...props } />;
    }
}

class App extends Component {
    /*
        User is one of:
         * not logged in
         * logged in but not subscribed
         * logged in and subscribed
         * logged in and expired (i.e. not subscribed)
     */
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            name: '',
            loading: false
        }
    }

    isLoggedIn = () => {
        return this.state.email !== '';
    };

    recordLogin = (email, name) => {
        const userData = {email: email, name: name};
        this.setState({loading: true});
        postData(checkout_url, userData)
            .then((data) => {
                console.log(data); // JSON data parsed by `response.json()` call
                userData.session = data;
                userData.loading = false;
                this.setState(userData);
            });
    };

    loggedInView = () => {
        const LoginOrLoader = orLoader(()=>this.state.loading, LoginComponent);
        const PayNowOrShowListing = (props) => {
            if (this.state.session && this.state.session.is_active_subscriber) {
                return <ReadingList {...props} />;
            } else {
                return <PayComponent { ...props} />;
            }
        };

        return <div className="logged-in">
            <LoginOrLoader  name={this.state.name}
                             email={this.state.email}
                             recordLogin={this.recordLogin}/>
            <PayNowOrShowListing
                email={this.state.email}
                session={this.state.session}
                booklist={this.state.session.bookList}
            />
        </div>
    };

    loggedOutView = () => {
        const LoginOrLoader = orLoader(()=>this.state.loading, LoginComponent);
        return <div className="logged-out">
            <LoginOrLoader  name={this.state.name}
                             email={this.state.email}
                             recordLogin={this.recordLogin}/>
        </div>
    }

    render() {

        return (
            <div className="subscription-process">
                <p className="back-link"><a href="https://ruthwrites.online">Back to the blog</a></p>
                <h1>Ruth Writes: The Lost Things</h1>
                <img src="/ruth_writes_logo.png" className="logo" alt="Logo: A room of one's own"/>
                {this.isLoggedIn() && this.loggedInView() }
                {this.state.session && this.state.session.cancelled &&
                    <CancelledComponent session={this.state.session} />
                }
                {this.isLoggedIn() || this.loggedOutView() }
                <WelcomeComponent/>

            </div>
        );
    }

}

export default App;
