import React, {Component} from 'react';
import {StripeKey} from './Settings';
const stripe = window.Stripe(StripeKey);

class PayComponent extends Component {
    constructor(props) {
        super(props);
        this.CHECKOUT_SESSION_ID = props.session.checkoutSession.id;
    }

    pay = (event) => {
        event.preventDefault();
        stripe.redirectToCheckout({
            // Make the id field from the Checkout Session creation API response
            // available to this file, so you can provide it as parameter here
            // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
            sessionId: `${this.CHECKOUT_SESSION_ID}`
        }).then(function (result) {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
        });
    };
    render() {
        return (
            <div>
                <p>You're signed in as: "{this.props.email}". Looks like you don't have an active subscription. </p>
                <button onClick={this.pay}>Pay now and start reading</button>
            </div>
        );
    }
}

export default PayComponent;
