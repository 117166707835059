import React, {Component} from 'react';
import {rootURL} from './Settings';
import * as jwt_decode from "jwt-decode";
import {CognitoAuth} from "amazon-cognito-auth-js";


const authData = {
    ClientId : '6o1e0dbdmn290e5i0gpktt0cp7', // Your client id here
    AppWebDomain : 'ruthwrites.auth.eu-west-1.amazoncognito.com',
    TokenScopesArray : ['phone', 'email', 'profile','openid', 'aws.cognito.signin.user.admin'], // e.g.['phone', 'email', 'profile','openid', 'aws.cognito.signin.user.admin'],
    RedirectUriSignIn : `${rootURL}/`,
    RedirectUriSignOut : `${rootURL}/`,
    UserPoolId : 'eu-west-1_3mTMA4uOc' // Your user pool id here
};

const auth = new CognitoAuth(authData);

class LoginComponent extends Component {
    constructor(props) {
        super(props);

        const curUrl = window.location.href;
        const app = this;
        auth.userhandler = {
            onSuccess: function(result) {
                const token = jwt_decode(result.getIdToken().getJwtToken());
                localStorage.setItem('cognito-email', token.email);
                app.props.recordLogin(token.email, token.name);
            },
            onFailure: function(err) {
                console.log("Error!");
            }
        };
        if (!props.email) {
            auth.parseCognitoWebResponse(curUrl);
        }
    }
    handleSubmit = (event) => {
        event.preventDefault();
        auth.getSession();
    };
    handleLogout = (event) => {
        event.preventDefault();
        localStorage.removeItem('cognito-email');
        auth.signOut();
    };

    render() {
        if (this.props.email) {
            return (
                <div>
                    <p>You're logged in, {this.props.name}</p>
                    <button onClick={this.handleLogout}>Log out</button>
                </div>
            );
        }
        return(
        <div>
            <p>Ready to start reading?</p>
            <form onSubmit={this.handleSubmit}>
                <p>
                    <button>Log in to get started</button>
                </p>
            </form>
        </div>
        );
    }
}

export default LoginComponent;
